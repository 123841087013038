import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"faq"} />
      <Helmet>
        <title>SSS | BIGGYM Fitness SSS</title>
        <meta
          name={"description"}
          content={"Sorularınız mı var? Cevaplarımız Var!"}
        />
        <meta property={"og:title"} content={"SSS | BIGGYM Fitness SSS"} />
        <meta
          property={"og:description"}
          content={"Sorularınız mı var? Cevaplarımız Var!"}
        />
        <meta
          property={"og:image"}
          content={"https://uvixens.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uvixens.com/img/icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://uvixens.com/img/icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="100px 0 100px 0" quarkly-title="FAQ-8">
        <Text
          margin="0px 0px 15px 0px"
          font="normal 600 42px/1.2 --fontFamily-sans"
          color="--darkL1"
        >
          SSS Bölümümüze Hoş Geldiniz
        </Text>
        <Text
          margin="0px 0px 70px 0px"
          font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
          color="#555a5f"
          sm-margin="0px 0px 50px 0px"
        >
          Yeni bir fitness yolculuğuna çıkmak birçok soruyu beraberinde
          getirebilir ve biz de kafanızdaki karışıklıkları gidermek için
          buradayız. Bu SSS bölümü, üyelik seçeneklerinden ilk dersinize ne
          getirmeniz gerektiğine kadar her şeyi kapsayan, aldığımız en yaygın
          soruları ele almak için tasarlanmıştır. Sorunuz burada
          yanıtlanmadıysa, doğrudan bize ulaşmaktan çekinmeyin. Yardım etmek
          için her zaman buradayız!
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="50px 50px"
          lg-grid-template-columns="repeat(2, 1fr)"
          md-grid-template-columns="1fr"
          sm-grid-gap="35px 0"
        >
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              İlk Dersime Ne Getirmeliyim?
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Bir su şişesi, rahat egzersiz kıyafetleri ve tercih ederseniz
              kendi egzersiz havlunuzu ve yoga matınızı getirin. Antrenmanınız
              için gereken tüm ekipmanı biz sağlıyoruz.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Katılmak için Formda Olmam Gerekiyor mu?
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Kesinlikle hayır! BIGGYM Fitness tüm fitness seviyelerindeki
              bireyleri ağırlamaktadır. Yeni başlayanlardan ileri düzey
              sporculara kadar çeşitli sınıflar ve kişiselleştirilmiş eğitimler
              sunuyoruz.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Ne Tür Üyelik Planları Sunuyorsunuz?
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Aylık, yıllık ve aile paketleri de dahil olmak üzere yaşam
              tarzınıza uyacak çeşitli üyelik planlarımız var. Her plan farklı
              avantajlar sunar, böylece sizin için en uygun olanı
              seçebilirsiniz.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Satın Almadan Önce Deneyebilir miyim?
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Evet, yeni üyeler için bir kerelik ücretsiz deneme sınıfı
              sunuyoruz. Bu, bir taahhütte bulunmadan önce topluluğumuzu ve
              hizmetlerimizi deneyimlemenin harika bir yoludur.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Çalışma Saatleriniz Nelerdir?
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Spor salonumuz, tüm programlara uyum sağlamak için sabahın erken
              saatlerinden akşamın geç saatlerine kadar açıktır. Belirli
              saatleri web sitemizde bulabilir veya doğrudan bizimle iletişime
              geçebilirsiniz.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Kişisel Eğitim Sunuyor musunuz?
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Evet, hedeflerinize ve ihtiyaçlarınıza göre bir fitness planı
              hazırlayabilen sertifikalı eğitmenlerle kişisel eğitim seansları
              sunuyoruz.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Contacts />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
